import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Sidebar, Segment, Sticky, Ref } from "semantic-ui-react";
import "../css/styles.css";
import "../css/responsive.css";
import "../css/styles.less";
import "prismjs/themes/prism-okaidia.css";
import Footer from "./Footer";
import SEO from "./SEO";
import SiteContext from "../SiteContext";

export const menuItems = [
  { name: "Home", path: "/", exact: true, icon: "home", inverted: true },
  { name: "About", path: "/about", exact: true, icon: "info circle" },
  { name: "Contact Us", path: "/contact", exact: true, icon: "mail" }
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const { isSideBarVisible } = React.useContext(SiteContext);
  const pusherRef = React.useRef();
  const segmentRef = React.useRef();

  return (
    <div >
      <HeaderMenu
        Link={Link}
        pathname={pathname}
        items={menuItems}
      />
      <Sidebar.Pushable >
        <SEO path={pathname} />
        <SidebarMenu
          Link={Link}
          pathname={pathname}
          items={menuItems}
          visible={isSideBarVisible}
        />

        <Sidebar.Pusher dimmed={isSideBarVisible} style={{ minHeight: "100vh", background: "white" }}>

          <div style={{
            paddingBottom: "10em",
            height: isSideBarVisible ? "100vh" : undefined,
            overflow: isSideBarVisible ? "hidden" : undefined
          }}>
            {props.children}
          </div>

          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>

    </div>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
