import * as React from "react";
import { Segment, Container, Icon, Divider } from "semantic-ui-react";

export default () => {
  return (
    <Segment
      as={"footer"}
      inverted
      vertical
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
      >
      <Container textAlign="center" style={{ marginBottom: 20 }}>
        <Divider inverted={true} style={{ marginBottom: 30 }} />
        <p>© {new Date().getFullYear()} Rough Draft Developments</p>
      </Container>
    </Segment>
  );
};
