import * as React from "react";
import { navigate } from 'gatsby';
import { GatsbyLinkProps } from "gatsby-link";
import { MenuProps, MenuItem } from "../Menu";
import { Menu, Icon, Sidebar } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react";
import { useContext } from "react";
import SiteContext from "../../SiteContext";

interface SidebarMenuProps extends MenuProps {
  visible?: boolean;
  Link: React.ComponentClass<GatsbyLinkProps<any>>;
  target?: any;
}

const SidebarMenu = ({ items, pathname }: SidebarMenuProps) => {
  const { isSideBarVisible: visible, setSideBarVisibility } = useContext(SiteContext);
  const isActive = (item: MenuItem) => (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
  const activeItem = items.find((item: MenuItem) => isActive(item)) || {} as MenuItem;

  const show = React.useCallback(() => setSideBarVisibility(true), [setSideBarVisibility]);
  const hide = React.useCallback(() => setSideBarVisibility(false), [setSideBarVisibility]);


  return (
    <Sidebar
      as={Menu}
      animation="uncover"
      width="thin"
      visible={visible}
      icon="labeled"
      vertical
      direction="right"
    >
      {items.map((item) => {
        const active = isActive(item);
        return (
          <Menu.Item onClick={() => {
            navigate(item.path);
            setTimeout(hide , 50);
          }} active={active} key={item.path}>
            <Icon name={item.icon as SemanticICONS} />
            {item.name}
          </Menu.Item>
        );
      })}
    </Sidebar>
  );
};

export default SidebarMenu;