import * as React from "react";
import { Container, Menu, Header, Visibility, Sticky } from "semantic-ui-react";
import { MenuProps } from "../Menu";
import rddLogo from "../../assets/logo.png";
import SiteContext from "../../SiteContext";
import { useState } from "react";

interface HeaderMenuProps extends MenuProps {
  inverted?: boolean;
}

const HeaderMenu = ({ items, pathname, Link, inverted }: HeaderMenuProps) => {
  const [menuFixed, setMenuFixed] = useState(false);
  const { setSideBarVisibility, isSideBarVisible } = React.useContext(SiteContext);

  const stickTopMenu = () => setMenuFixed(true);
  const unStickTopMenu = () => setMenuFixed(false);

  const toggleSidebar = React.useCallback(() => {
    window.scrollTo(0, 0);
    setSideBarVisibility(!isSideBarVisible);
  }, [setSideBarVisibility, isSideBarVisible]);

  const fixedMenuStyleMobile = {
    backgroundColor: 'white',
    border: '1px solid #ddd',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  }
  const menuStyleMobile = {
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '0px',
    transition: 'box-shadow 0.5s ease, padding 0.5s ease',
  }

  return (

    <Visibility
      onBottomPassed={stickTopMenu}
      onBottomVisible={unStickTopMenu}
      once={false}
    >
      {/* MOBILE MENU */}
      <Menu
        size="large"
        className="mobile only"
        borderless
        style={menuFixed ? fixedMenuStyleMobile : menuStyleMobile}
        fixed={menuFixed ? "top" : undefined}
      >
        <Menu.Item as={Link} position="left" to={"/"}  >
          <img style={{ width: 80 }} src={rddLogo} />
        </Menu.Item>
        <Menu.Item
          as="a"
          position="right"
          className="mobile only"
          icon="sidebar"
          disabled={isSideBarVisible}
          onClick={toggleSidebar}
        />
      </Menu>
      
      {/* WEB MENU */}
      <Menu
        size="large"
        className="mobile hidden"
        pointing
        secondary
        inverted={inverted}
        fixed={menuFixed ? "top" : undefined}
        style={{ paddingTop: 10, backgroundColor: "white", marginTop: 0 }}
      >
        <Container>
          <Menu.Menu position={"left"}>
            <Link to={"/"}>
              <Menu.Header className="mobile hidden" style={{ display: "flex", alignItems: "baseline" }}>
                <img style={{ height: 50, marginRight: 5 }} src={rddLogo} />
                <Header as={"p"} className="small-caps" style={{ fontSize: "1.2rem" }}>Rough Draft Developments</Header>
              </Menu.Header>
            </Link>
          </Menu.Menu>
          <Menu.Menu position={"right"}>
            {items.map((item) => {
              const active = (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
              return <Menu.Item
                as={Link}
                className="mobile hidden"
                name={item.name}
                to={item.path}
                key={item.path}
                active={active}
              />;
            })}
          </Menu.Menu>
        </Container>
      </Menu>
    </Visibility>
  )
}

export default HeaderMenu
